import {call, put, takeLatest} from "redux-saga/effects";
import {CREATE_PROFILE, GET_COUNTRY, GET_LOCATION, SEARCH, SET_SEARCH, UPDATE_PROFILE, UPLOAD_AVATAR} from "../actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {AxiosResponse} from 'axios';
import axios from '../../boot/axios';
import {GET, POST} from "../../api";
import ApiRouts from "../../boot/api-routes";
import {INFORMATION_SUCCESS} from "../global/action";
import {AppSwal} from "../../global/functions";
import {Initialize} from "../../guards/initialize";
import store from "../store";
import {TOGGLE_PAGE} from "../tags/action";

export function* UpdateProfile(action: PayloadAction<{ data: any }>) {
    try {
        const data = action.payload.data;
        const setMonthProfile = () => {
            switch (data?.month) {
                case "January":
                    return "01"
                case "February":
                    return "02";
                case "March":
                    return "03";
                case "April":
                    return "04";
                case "May":
                    return "05";
                case "June":
                    return "06";
                case "July":
                    return "07";
                case "August":
                    return "08";
                case "September":
                    return "09";
                case "October":
                    return "10";
                case "November":
                    return "11";
                case "December":
                    return "12"
            }
        }
        let city = null;
        let country = null
        if (data?.country?.hasOwnProperty('name')){
            country = data?.country?.name;
            city = data?.city?.name;
        }else{
            country = data?.country;
            city = data?.city;
        }
        const payload = {
            username: data.username,
            birthdate: data.year + "/" + setMonthProfile() + "/" + data.day,
            gender: data.gender,
            nationality: data.nationality,
            religion: data.religion,
            language: data.language,
            current_password: data.current_password,
            password: data.password,
            location: country+','+city,
            about: data.about,
            private: data?.private == "true" ? 1 : 0,
        }
        const response: AxiosResponse = yield call(POST, ApiRouts?.UPDATE_PROFILE, payload)
        if (response && response.status == 200 ) {
            store.dispatch({
                type: TOGGLE_PAGE
            })

            Initialize();
            AppSwal({value: "Your profile information updated successfully", type: "success"})
        }else{}
    } catch (error) {

    }
}

export function* CreateProfile(action: PayloadAction<{ data: any, location: any }>) {
    try {
        const setMonthProfile = () => {
            switch (data?.month) {
                case "January":
                    return "01"
                case "February":
                    return "02";
                case "March":
                    return "03";
                case "April":
                    return "04";
                case "May":
                    return "05";
                case "June":
                    return "06";
                case "July":
                    return "07";
                case "August":
                    return "08";
                case "September":
                    return "09";
                case "October":
                    return "10";
                case "November":
                    return "11";
                case "December":
                    return "12"
            }
        }
        const data = action.payload.data
        const payload = {
            username: data.username,
            birthdate: data.year + "/" + setMonthProfile() + "/" + data.day,
            gender: data.gender,
            nationality: data.nationality,
            religion: data.religion,
            language: data.language,
            location: data.country + ',' + data.city,
        }
        const response: AxiosResponse = yield call(POST, ApiRouts?.INFORMATION, payload)
        if (response) {
            yield put({
                type: INFORMATION_SUCCESS, data: {
                    user: response.data.data,
                }
            })
        }
        if (response.status === 413) {
            AppSwal({value: "Your file is too large legal file size 1000kb", type: "warning"})
        }
    } catch (error) {
        console.log(error)
    }
}

export function* UploadAvatar(action: PayloadAction<{ avatar: any }>) {
    try {
        const formData = new FormData();
        formData.append('avatar', action?.payload?.avatar);
        const response: AxiosResponse = yield call(axios.post, ApiRouts?.UPLOAD_AVATAR, formData)
        if (response) {
            yield put({
                type: INFORMATION_SUCCESS, data: {
                    user: response.data.data,
                }
            })

        }
    } catch (error) {
        console.log(error)
    }
}


export function* GetLocation() {
    try {

        const response: AxiosResponse = yield call(GET, `https://mithrarya.com/states.json`, {})
        if (response) {
            yield put({
                type: GET_COUNTRY,
                data: {
                    country: response.data.data,
                    cities: response.data.data.states,
                }
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* Search(action: PayloadAction<{ search: any }>) {
    try {
        const formData = new FormData();
        formData.append('text', action?.payload?.search);
        const response: AxiosResponse = yield call(axios.post, ApiRouts?.SEARCH, formData)
        if (response) {
            yield put({
                type: SET_SEARCH,
                data: response.data
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* profileSaga() {
    yield takeLatest(GET_LOCATION, GetLocation);
    yield takeLatest(CREATE_PROFILE, CreateProfile);
    yield takeLatest(UPDATE_PROFILE, UpdateProfile);
    yield takeLatest(UPLOAD_AVATAR, UploadAvatar);
    yield takeLatest(SEARCH, Search);
}

export default profileSaga;
