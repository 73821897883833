import {call, put, select, takeLatest} from "redux-saga/effects";
import {CHAT_LIST, SET_CHAT_LIST, SET_PUBLIC_CHAT} from "../actions";
import {AxiosResponse} from 'axios';
import {GET, POST} from "../../api";
import ApiRouts from "../../boot/api-routes";
import {
    CREATE_GROUP_CHAT, DELETE_CHAT, DO_REMOVE_PARTICIPANT,
    GET_CHAT,
    GET_PUBLIC_CHAT_LIST, JOIN_CHAT, LEAVE_CHAT, READ_CHAT, REMOVE_PARTICIPANT, SEND_FILE_MESSAGE,
    SEND_MESSAGE,
    SEND_VOICE_MESSAGE,
    SET_CHAT, SET_CURRENT_CHAT,
    SetMessage, SHARE_POST
} from "./action";
import {PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "../types";
import ChatList from "./chat-list";

export function* GetChatList() {
    try {
        const response: AxiosResponse = yield call(GET, <string>ApiRouts?.CHAT_LIST, {})
        if (response) {
            yield put({
                type: SET_CHAT_LIST,
                data: {
                    data: response.data.data,
                }
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* GetPublicChat() {
    try {
        const response: AxiosResponse = yield call(GET, <string>ApiRouts?.PUBLIC_CHAT_LIST, {})
        if (response) {
            yield put({
                type: SET_PUBLIC_CHAT,
                data: response.data.data,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* CreateGroupChat(action: PayloadAction<{ data: any }>) {
    try {
        console.log(action?.payload?.data)

        const formData = new FormData();
        action?.payload?.data.data.addToChatList.map((item: any) => {
            formData.append("users[]", item?.user_id)
        })
        formData?.append("avatar", action?.payload?.data.data?.avatar)
        formData?.append("title", action?.payload?.data.data?.title)
        formData?.append("private", action?.payload?.data.data?.privateChat)
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.GROUP_CHAT_LIST, formData)
        if (response) {
            yield put({
                type: CHAT_LIST,
            })

        }
    } catch (error) {
        console.log(error)
    }
}

export function* SendMessage(action: PayloadAction<{ data: any }>) {
    try {
        const formData = new FormData();
        formData.append("message", action.payload.data?.message)
        formData.append("conversation", action.payload.data?.conversation)
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.SEND_MESSAGE, formData)
        if (response) {
            SetMessage(response?.data)
        }
    } catch (error) {
        console.log(error)
    }
}

export function* SharePost(action: PayloadAction<{ user: any, post: any }>) {
    try {
        const formData = new FormData();
        action.payload?.user.map((item: any) => {
            formData.append("users[]", item)
        })
        formData.append("post", action.payload?.post)
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.SHARE_POST, formData)
        if (response) {

        }
    } catch (error) {
        console.log(error)
    }
}

export function* LeaveChat(action: PayloadAction<{ id: any }>) {
    try {
        const formData = new FormData();
        formData.append("conversation", action.payload.id)
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.LEAVE_CHAT, formData)
        if (response) {
        }
    } catch (error) {
        console.log(error)
    }
}

export function* ReadChat(action: PayloadAction<{ data: any }>) {
    try {
        const formData = new FormData();
        formData.append("conversation", action.payload.data)
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.CHAT_READ, formData)
        if (response) {
        }
    } catch (error) {
        console.log(error)
    }
}

export function* SendVoiceMessage(action: PayloadAction<{ data: any }>) {
    try {
        const formData = new FormData();
        formData.append("message", action.payload.data?.message)
        formData.append("conversation", action.payload.data?.conversation)
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.VOICE_MESSAGE, formData)
        if (response) {
            SetMessage(response?.data)
        }
    } catch (error) {
        console.log(error)
    }
}
export function* SendFileMessage(action: PayloadAction<{ data: any }>) {
    try {
        const formData = new FormData();
        formData.append("message", action.payload.data?.message)
        formData.append("conversation", action.payload.data?.conversation)
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.SEND_FILE, formData)
        if (response) {
            SetMessage(response?.data)
        }
    } catch (error) {
        console.log(error)
    }
}
export function* LoadChat(action: PayloadAction<{ data: any }>) {
    try {

        const formData = new FormData();
        formData.append("conversation", action.payload.data?.conversation)
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.GET_CHAT, formData)
        if (response) {
            yield put({
                type: SET_CHAT,
                data: response.data.data
            })
            yield put({
                type: READ_CHAT,
                payload: {
                    data: action.payload.data?.conversation
                }
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* RemoveParticipant(action: PayloadAction<{ data: any }>): any {
    try {

        const formData = new FormData();
        formData.append("conversation", action.payload?.data?.conversation)
        formData.append("user", action.payload?.data?.user)
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.CHAT_REMOVE_PARTICIPANT, formData)

        if (response) {
            const currentState = yield select((state: RootState) => state);
            const participants = currentState?.currentChat?.participants?.filter((item: any) => {
                return item.user_id != action.payload?.data?.user
            })
            let data = currentState?.currentChat;
            data.participants = participants;
            yield put({
                type: SET_CURRENT_CHAT,
                data
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* JoinChat(action: PayloadAction<{ data: any }>): any {
    try {

        const formData = new FormData();
        formData.append("conversation", action.payload?.data)
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.JOIN_CHAT, formData)
        if (response){

        }
    } catch (error) {
        console.log(error)
    }
}
export function* DeleteChat(action: PayloadAction<{ data: any }>): any {
    try {

        const formData = new FormData();
        formData.append("conversation", action.payload?.data)
        const response: AxiosResponse = yield call(POST, <string>ApiRouts?.DELETE_CHAT, formData)
        if (response){

        }
    } catch (error) {
        console.log(error)
    }
}

function* chatSaga() {
    yield takeLatest(CHAT_LIST, GetChatList);
    yield takeLatest(GET_PUBLIC_CHAT_LIST, GetPublicChat);
    yield takeLatest(CREATE_GROUP_CHAT, CreateGroupChat);
    yield takeLatest(SEND_MESSAGE, SendMessage);
    yield takeLatest(SEND_VOICE_MESSAGE, SendVoiceMessage);
    yield takeLatest(GET_CHAT, LoadChat);
    yield takeLatest(DELETE_CHAT, DeleteChat);
    yield takeLatest(LEAVE_CHAT, LeaveChat);
    yield takeLatest(REMOVE_PARTICIPANT, RemoveParticipant);
    yield takeLatest(READ_CHAT, ReadChat);
    yield takeLatest(SHARE_POST, SharePost);
    yield takeLatest(JOIN_CHAT, JoinChat);
    yield takeLatest(SEND_FILE_MESSAGE, SendFileMessage);
}

export default chatSaga;
